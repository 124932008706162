.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fdfaed;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:5% 8%;
  box-shadow: 0.5px 2px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
}

.logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.auth {
  margin-bottom: 0px;
}

.auth-text {
  font-size: 25px;
  letter-spacing: 8px;
  margin: 5px;
  font-weight: bolder;
}

.google-login-button {
  padding: 10px 60px;
  margin: 5px;
  font-size: 16px;
  border: 1px solid rgb(130, 130, 130);
  border-radius: 10px;
  background-color: #ffffff;
  color: black;
  cursor: pointer;
}

.google-login-button:hover {
  background-color: #b8b8b8;
}

.google-icon{
  width:20px;
  height:20px;
  margin-right:10px;
}

.google-login-btn-text{
  position:relative;
  bottom:2px;
}