@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
  overflow: hidden;
  overflow-y: auto;
}

img {
  max-width: 100%;
  max-height: 100%;
}

*{
  margin:0;
  padding:0;
}